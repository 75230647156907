<template>
  <div
    class="choose-game"
    :class="{ 'is-error': errorIsVisible, mobile: isMobile }"
  >
    <header class="header">
      <h2 class="title">{{ t('bonuses.shouldChooseGame') }}</h2>
      <p class="subtitle">{{ t('bonuses.chooseGameSubtitle') }}</p>
    </header>
    <div class="content">
      <GameCard
        v-for="game in games"
        :key="game.gameId"
        :game="game"
        :selected="selectedGameId === game.gameId"
        :mobile="isMobile"
        @click="onCardClick(game)"
      />
      <template v-if="!games?.length">
        <template v-if="isMobile">
          <StSkeletonLoader
            v-for="i in 2"
            :key="i"
            height="140px"
            bg-color="var(--background-primary)"
            :shimmer-opacity="0.28"
            width="159px"
          />
        </template>
        <template v-else>
          <StSkeletonLoader
            v-for="i in 3"
            :key="i"
            bg-color="var(--background-primary)"
            :shimmer-opacity="0.28"
            height="151.5px"
            width="172px"
          />
        </template>
      </template>
    </div>
    <footer class="footer">
      <StButton :size="buttonSize" class="button" @click="onSubmitClick">
        {{ t('bonuses.buttonChooseGame') }}
      </StButton>
      <Transition>
        <span v-if="errorIsVisible" class="error">
          {{ t('bonuses.errorText') }}
        </span>
      </Transition>
    </footer>
  </div>
</template>

<script setup lang="ts">
import type { Game } from '@st/casino/types'
import GameCard from './parts/GameCard.vue'

const { t } = useI18n()
const router = useRouter()
const { isMobile } = usePlatform()
const stFetch = useRawStFetch()
const { open } = useToast()

const props = defineProps<{
  gameIds: string[]
  userFreespinProgramId: string
}>()

const selectedGameId = ref<null | number>(null)
const errorIsVisible = ref<boolean>(false)

function onCardClick(game: Game) {
  if (game.forbiddenByCountry) return

  const id = game.gameId
  errorIsVisible.value = false
  if (selectedGameId.value === id) {
    selectedGameId.value = null
    return
  }
  selectedGameId.value = id
}

async function onSubmitClick() {
  if (!selectedGameId.value) {
    errorIsVisible.value = true
    return
  }
  try {
    const response = await stFetch('/user-freespin-campaign/create', {
      method: 'post',
      body: {
        userFreespinProgramId: Number(props.userFreespinProgramId),
        gameId: Number(selectedGameId.value),
      },
    })
    if (response) router.push('/bonuses')
  } catch {
    open({ label: t('bonuses.errorSomethingWentWrong'), type: 'negative' })
  }
}

const buttonSize = computed(() => (isMobile.value ? 'l' : 'xl'))
const { data: games } = useStFetch('/casino/game/find', {
  method: 'POST',
  body: {
    params: {
      gameId: Array.isArray(props.gameIds)
        ? props.gameIds?.map((i) => Number(i))
        : [Number(props.gameIds)],
    },
    pagination: {
      orderBy: [
        {
          fieldName: 'name' as const,
          sortOrder: 'DESC' as const,
        },
      ],
    },
  },

  transform: (response) => response.data,
})
</script>

<style scoped>
.choose-game {
  position: relative;
  width: 596px;

  &::after {
    content: '';

    position: absolute;
    right: 0;
    bottom: -32px;
    left: 0;
    transform-origin: 50% 0;
    transform: scaleY(0);

    height: 56px;

    background-color: var(--background-base);
    border-bottom-right-radius: var(--border-radius-150);
    border-bottom-left-radius: var(--border-radius-150);

    transition: transform 0.3s ease-in-out;
  }

  &.is-error {
    &::after {
      transform: scaleY(1);
    }
  }

  .header {
    padding: var(--spacing-300) var(--spacing-300) 0 var(--spacing-300);
    padding-bottom: var(--spacing-250);
  }

  .title {
    margin: 0;
    margin-bottom: var(--spacing-050);
    font: var(--desktop-text-2xl-semibold);
  }

  .subtitle {
    margin: 0;
    font: var(--desktop-text-md-medium);
    color: var(--text-secondary);
  }

  .content {
    overflow: hidden scroll;
    display: grid;
    grid-template-columns: 172px 172px 172px;
    gap: var(--spacing-250) var(--spacing-200);

    max-height: 520px;
    padding: var(--spacing-250) var(--spacing-300);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-200);
    align-items: center;

    padding: var(--spacing-500) var(--spacing-300) var(--spacing-300)
      var(--spacing-300);
  }

  .button {
    width: 100%;
  }

  .error {
    position: absolute;
    z-index: 1;
    bottom: -8px;

    font: var(--desktop-text-xs-medium);
    color: var(--text-error);
  }
}

.choose-game.mobile {
  width: 100%;

  .header {
    padding: var(--spacing-150) var(--spacing-200) 0 var(--spacing-200);
  }

  .title {
    font: var(--mobile-title-2-semibold);
  }

  .subtitle {
    font: var(--mobile-text-regular);
  }

  .content {
    grid-template-columns: 160px 160px;
    gap: var(--spacing-200) var(--spacing-150);
    justify-content: center;

    max-height: 75dvh;
    padding: var(--spacing-200);
  }

  .error {
    font: var(--mobile-caption-1-regular);
  }
}

.v-enter-active,
.v-leave-active {
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
